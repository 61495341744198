import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Container } from '@material-ui/core'
import useStyles from './style'
import UploadCard from '../../components/compounds/upload-card'

const HomePage = () => {
  const match = useRouteMatch()
  const classes = useStyles()

  return (
    <Container className={ classes.homeContainer }>
      <div className={ classes.gridContainer }>
        <div className={ classes.uploadCardContainer }>
          <UploadCard />
        </div>

        <div className={ classes.folderGridContainer }>
          <Switch>
            <Route exact path={ `${ match.path }` } component={ React.lazy(() => import('./pages/folders/folders')) } />
          </Switch>
        </div>
      </div>
    </Container>
  )
}

export default HomePage
