import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import BackupIcon from '@material-ui/icons/Backup'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import validationSchema from 'misc/schemas/uploadSchema'
import { useLanguages } from 'state/react-query/query/getLangauges'
import CustomButton from 'components/atoms/Button'
import useStyles from './style'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { HomeContext } from '../../../pages/home/contexts/home-context'
import { pushNotification } from '../../../state/store/notifications'
import { severity } from '../../../types/common'
import { useQuery } from '../../../misc/hooks/use-query'
import { useFolder } from '../../../pages/home/react-query/folder'
import UppyDashboard from './uppy'
import Uppy, { UploadResult } from '@uppy/core'
import Webcam from '@uppy/webcam'
import Audio from '@uppy/audio'
import ScreenCapture from '@uppy/screen-capture'
import DropTarget from '@uppy/drop-target'
import Tus from '@uppy/tus'
import { useApiConfig } from '../../../misc/contexts/ApiConfigContext'
import { DocumentTypeOptions } from '../../../pages/home/types'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient } from 'react-query'

const formikInitialValues = {
  language: '',
  type: 'caption',
  punctuations: false
}

/**
 * A feature loaded upload component handling the APIs and upload logic
 */
const UploadCard = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [ language, setLanguage ] = useState<string>('')
  const [ showCheck, setShowCheck ] = useState(false)
  const { data } = useLanguages()
  const [ checked, setChecked ] = useState(true)
  const [ state, setState ] = useState({
    Subtitling: true
  })
  const { hasWriteAccess } = useContext(HomeContext)
  const fId = useQuery().get('f')
  const folderId = parseInt(fId ?? '', 10)
  const { data: folder } = useFolder(folderId)
  const langId = useQuery().get('lang')
  const [ enableFiles, setEnableFiles ] = useState<boolean>(false)
  const [ uploading, setUploading ] = useState<boolean>(false)
  const [ errorUploading, setErrorUploading ] = useState<boolean>(false)
  const [ uppy, setUppy ] = useState<Uppy | null>(null)
  const { appConfig, bearerToken } = useApiConfig()
  const queryClient = useQueryClient()

  useEffect(() => {
    const lang = data?.result.find((l) => l.key === langId)
    if (lang) {
      setLanguage(lang.key)
    }
  }, [ langId ])

  const getUppy = React.useMemo(() => {
    return new Uppy({
      onBeforeFileAdded: (currentFile, files) => {
        const length = Object.keys(files).length
        if (length > 0 || currentFile.name.length > 0) {
          setEnableFiles(true)
          setErrorUploading(false)
        }
        return true
      },
      restrictions: {
        allowedFileTypes: [ 'video/*', 'audio/*', '.ts' ],
        maxTotalFileSize: appConfig.maxFileSize,
      }
    })
      .use(Webcam, {
        showVideoSourceDropdown: true,
        showRecordingLength: true,
        modes:[
          'video-audio',
        ],
        preferredVideoMimeType: 'video/mkv',
      })
      .use(Audio, {
        showAudioSourceDropdown: true,
      })
      .use(ScreenCapture, {
        preferredVideoMimeType: 'video/mkv',
      })
      .use(DropTarget, { target: document.body })
      .use(Tus, {
        endpoint: `https://${ appConfig.baseUrl || 'api.scriptix.io' }/api/v3/files/`,
        retryDelays: [ 0, 1000 ],
        headers: {
          'Authorization': `Bearer ${ bearerToken }`,
        },
        withCredentials: true,
      })
  }, [ appConfig, bearerToken ])

  useEffect(() => {
    setUppy(getUppy)
  }, [ appConfig, bearerToken ])

  const { values, handleSubmit } = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      if (!uploading) {
        setUploading(true)
      }
    }
  })

  const handleOnDropError = useCallback((error?: string) => {
    pushNotification({ message: error || t('pleaseProvideAudioOrVideoFile'), severity: severity.error })
  }, [ t ])

  const handleOnUploadSuccess = useCallback((name?: string) => {
    pushNotification({ message: name
      ? `${ name }: successfully uploaded` 
      : t('documentSuccessfullyUploaded'),
    severity: severity.success })
  },
  [ t ]
  )

  const handleLangChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    values.language = event.target.value as string
    setLanguage(event.target.value as string)
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [ event.target.name ]: event.target.checked })
    if (state.Subtitling) values.type = 'document'
    else if (!state.Subtitling) {
      values.type = 'caption'
    }
  }

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChecked(true)
      values.punctuations = true
    } else {
      setChecked(false)
      values.punctuations = false
    }
  }

  const handleSelect = (punctuation: boolean) => {
    if (punctuation) {
      setShowCheck(true)
      setChecked(true)
      values.punctuations = true
    } else setShowCheck(false)
  }

  const resetUpload = () => {
    setLanguage('')
    values.language = ''
    values.punctuations = false
    setShowCheck(false)
    setEnableFiles(false)
    setUploading(false)
  }

  const handleUpload = async () => {
    setUploading(true)
    await uploadTus()
  }

  const uploadTus = async () => {
    if (!uppy) return

    if (folderId) {
      if (!folder?.result) return
      if (!hasWriteAccess?.(folder.result)) {
        pushNotification({ message: t('noWriteAccess'), severity: severity.error })
        return
      }
    }

    uppy.setMeta({
      language: values.language,
      document_type: values.type as DocumentTypeOptions,
      linked: false,
      folder_id: !isNaN(folderId) ? folderId : null,
      punctuation: values.punctuations?.toString() ? values.punctuations : 'true',
      keep_source: 'true',
      multichannel:'false',
      uuid: uuidv4()
    })

    let result: UploadResult
    if (errorUploading) {
      setErrorUploading(false)
      result = await uppy.retryAll()
    } else {
      result = await uppy.upload()
    }

    checkUploadResult(result)
  }

  const checkUploadResult = (result: UploadResult) => {
    if (result.failed.length > 0) {
      result.failed.forEach(file => {
        handleError(file.error)
      })
    } else if (result.successful.length > 0) {
      resetUpload?.()
      handleOnUploadSuccess()

      if (!isNaN(folderId)) {
        queryClient.invalidateQueries([ 'folder', folderId ])
      } else {
        queryClient.invalidateQueries([ 'folders' ])
      }

      setTimeout(() => {
        result.successful.forEach((file) => {
          uppy?.removeFile(file.id)
        })
      }, 7000)
    }

    setUploading(false)
  }

  const handleError = (error: string) => {
    // @ts-ignore
    if (error.isNetworkError) {
      handleOnDropError('Network error, please try again later or check your internet connection, ISP.')
      return
    }

    try {
      const errorStartIndex = error.lastIndexOf('response text: ') + 'response text: '.length
      const errorEndIndex = error.lastIndexOf(', request id')
      const errorText = error.substring(errorStartIndex, errorEndIndex)
      const errorObject = JSON.parse(errorText)
      handleOnDropError(errorObject.error.toUpperCase() + ': ' + errorObject.error_description)
    } catch (e) {
      handleOnDropError('Error while uploading files, please try again, refresh page or contact support. TU-01')
    }

    setErrorUploading(true)
    setUploading(false)
  }

  const hasAccess = folder?.result ? hasWriteAccess?.(folder.result) : !folder?.result && isNaN(folderId)

  return (
    <form onSubmit={ handleSubmit } className={ classes.formUpload }>
      <Paper elevation={ 3 } className={ classes.paperContainer }>
        <div className={ classes.dropzoneContainer }>
          {uppy && <UppyDashboard
            setEnableFiles={ setEnableFiles }
            setUploading={ setUploading }
            height={ 390 }
            uppy={ uppy }
          />}
        </div>
        
        <FormControl className={ classes.formControl }>
          <Select
            value={ language }
            onChange={ handleLangChange }
            displayEmpty
            inputProps={ { 'aria-label': 'Without label' } }
            className={ classes.selectFieldstyle }
            variant='outlined'
          >
            <MenuItem value='' disabled>
              {t('language')}
            </MenuItem>
            {data?.result.map((item: { key: string; name: string; hasPunctuation: boolean }) => (
              <MenuItem
                className={ classes.menuItemStyle }
                value={ item.key }
                key={ item.key }
                onClick={ () => handleSelect(item.hasPunctuation) }
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={ classes.switchStyle }>
          <Typography className={ classes.textStyle }>{t('transcription')}</Typography>
          <Switch
            checked={ state.Subtitling }
            onChange={ handleSwitchChange }
            color='default'
            name='Subtitling'
            inputProps={ { 'aria-label': 'primary checkbox' } }
          />
          <Typography className={ classes.textStyle }>{t('subtitling')}</Typography>
        </div>
        {showCheck ? (
          <FormControlLabel
            value='end'
            control={ <Checkbox color='primary' onChange={ handleCheckChange } checked={ checked } /> }
            label={ t('punctuations') }
            labelPlacement='end'
            className={ classes.checkBoxLabel }
          />
        ) : (
          <></>
        )}
        
        <CustomButton
          type='submit'
          fullWidth
          variant='contained'
          disabled={ !hasAccess || language.length === 0 || !enableFiles || uploading }
          color='primary'
          buttonContent={ t('upload') }
          classes={ { classes: classes.submit } }
          onClick={ async () => await handleUpload() }
          startIcon={ <BackupIcon /> }
        />

        <Box textAlign='right' fontSize='0.8rem' margin='5% 0% 0% 0%'>
          <Link component={ RouterLink } to={ !isNaN(folderId) ? `/upload?f=${ folderId }` : '/upload' }>
            Advanced
          </Link>
        </Box>
      </Paper>
    </form>
  )
}

export default memo(UploadCard)
