import { useState, useEffect } from 'react'

// Inspired by : https://formidable.com/blog/2021/stores-no-context-api/

type FnEmitter<T> = (v: T) => void
const createEmitter = <T>() => {
  const subscriptions = new Map<symbol, FnEmitter<T>>()
  return {
    emit: (v: T) => {
      // Update all subscribed local stores
      subscriptions.forEach(fn => fn(v))
    },
    subscribe: (fn: FnEmitter<T>) => {
      const key = Symbol()
      subscriptions.set(key, fn)
      return () => { subscriptions.delete(key) }
    },
  }
}

type createStoreSetFunction<T> = (op: (old: T) => T) => void
type createStoreUseFunction<T> = () => [ T, createStoreSetFunction<T> ]

const createStore = <T>(initialValues: T): [ createStoreUseFunction<T>, createStoreSetFunction<T> ] => {
  // Create a global store to share state in

  // Create an eventEmitter used to subscribe all users of this store
  const emitter = createEmitter<T>()

  // Global store
  let store: T = initialValues

  // Return global store for initiate local stores
  const getGlobalStore = (): T => store

  // Method to update global store
  const setGlobalStore: createStoreSetFunction<T> = (op: (old: T) => T) => {
    // Call the update callback and update the store
    store = op(store)
    // Notify all subscriptions when the store updates
    emitter.emit(store)
  }

  // Method to return to be used in components, this creates a local
  // copy of the global store and registers with the emitter in order
  // to retrieve global updates
  const useStore: createStoreUseFunction<T> = () => {
    // Initiate component with latest store for local usage
    const [ localStore, setLocalStore ] = useState(getGlobalStore())

    // Update local store when global store updates.
    // subscribe returns a cleanup so it will detach on unmount
    useEffect(() => {
      emitter.subscribe(setLocalStore)
    }, [])
    return [ localStore, setGlobalStore ]
  }

  return [ useStore, setGlobalStore ]

}

export { createStore }