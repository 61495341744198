import { BaseResponse } from '../../api'
import React from 'react'
import { SpeechToTextLanguageObject } from '@scriptix-io/scriptix-api-v3'
import {
  SpeechToTextSessionCreateInterface
} from '@scriptix-io/scriptix-api-v3/models/SpeechToTextSessionCreateInterface'

export type DocumentTypeOptions = 'document' | 'caption'

export type DownloadState = {
  open: boolean
  sessionId: string
  documentId: string
  documentType: DocumentTypeOptions
}

export type NewSubtitleState = {
  open: '' | 'caption' | 'document'
  sessionId: string
  language: string
  duration: number
}

export type SttResponse = {
  duration: number
  language: string
  status: string
  session_id: string
  last_modified: string
}

export type Folder = {
  id: number
  name: string
  parent_id: number | null
  parent_folder?: Folder | undefined
  child_folders?: Folder[] | undefined
  permissions?: FolderPermission[] | undefined
  documents?: DocumentResponse[] | undefined
  last_modified?: string
}

export enum PermissionType {
  NO_ACCESS = 0,
  READ = 1,
  WRITE = 2
}

export type FolderPermission = {
  id: number
  group_id: number
  permission: PermissionType
  folder_id: number
}

export type DocumentResponse = {
  id: string
  filename: string
  language?: string
  last_modified: string
  type: string
  session: SttResponse
}

export type FolderResponse = BaseResponse<Folder>

type FolderDocument = {
  folders: Folder[]
  documents: DocumentResponse[]
}

export type FolderDocumentListResponse = BaseResponse<FolderDocument>

type PermissionFolderRequest = {
  group_id: number | null
  permission: PermissionType
}

export type FolderRequest = {
  name: string
  parent_id?: number | null
  permissions: PermissionFolderRequest[]
}

export type FolderUpdateRequest = {
  ids: string[]
  parent_id: number | null
  type: 1 | 2 | 3
}

export type BatchDeleteRequest = {
  ids: string[]
  type: 1 | 2 | 3
}

export interface MouseState {
  mouseX: number | null
  mouseY: number | null
}

export interface CheckboxProps {
  addSelectedFolder?: (id: number) => void
  removeSelectedFolder?: (id: number, name: string) => void
  addSelectedDocument?: (id: string, document_id: string) => void
  removeSelectedDocument?: (id: string) => void
}

export interface FolderProps extends CheckboxProps {
  data: FolderSessionTable[]
  handleClickFolder: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    id: number
  ) => void
  handleClickFile: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    documentId: string
  ) => void
}

export const DragType = {
  FOLDER: 'folder',
  FILE: 'file',
  NONE: 'none'
}

type Document = {
  document_type: DocumentTypeOptions
  filename: string
}

export type SessionRequest = {
  language: string
  punctuation?: boolean
  document?: Document
  folder_id?: number | null
  keep_source?: boolean
  multichannel?: boolean
}

export type SessionResponse = {
  session_id: string
  status: string
}

export interface SpeechToTextStatusResponse {
  count: number
  result: SessionResponse
  totalResults: number
}

export interface CustomUploadRequest extends SpeechToTextSessionCreateInterface {
  folder_id?: number | null
  keep_source?: boolean
}

export type CustomSessionRequest = {
  language: SpeechToTextLanguageObject
  punctuation: boolean
  folder_id?: number | null
  multichannel?: boolean
  keepSource?: boolean
  autoCreate?: 'document' | 'caption' | null
  setProgress?: (percentage: number) => void
  file: File
  onFinish?: (sessionId: string) => void
  transcript?: File | null
}

export type FolderSessionTable = {
  data: Folder | DocumentResponse
  type: 'folder' | 'session'
  lastModified: string
}

export interface DropResult {
  name: string
  id: string
}
