import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import ScriptixLogo from 'components/molecules/ScriptixLogo'
import NavBarButtons from './NavbarComponents/NavButtons'
// import NavBarSearch from './NavbarComponents/SearchInput'
import useStyles from './style'

// import { useTranslation } from 'react-i18next'

interface NavbarProps {
  Child?: React.ElementType
}

const NavBar: React.FC<NavbarProps> = ({ Child }) => {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  // const { t } = useTranslation()

  const handleClickHome = () => {
    if (location.pathname != '/') history.push('/')
  }

  return (
    <Grid container>
      <AppBar position='sticky' className={ classes.appBar }>
        {/* <div className={ classes.announcement }>
          { t('welcomeToOurNewPortalLookingForOurOldPortalYouCanFindIt') } { ' ' }
          <a href="https://api.scriptix.io" target="_blank" rel="noreferrer">{ t('here!') }</a>
        </div> */}
        <Toolbar>
          <div className={ classes.toolbarLeft } onClick={ handleClickHome } role='presentation'>
            <ScriptixLogo variant='navbar' logoHeight={ 48 } />
          </div>
          <div className={ classes.toolbarCenter }>{/* <NavBarSearch /> */}</div>
          <div className={ classes.toolbarRight }>
            <NavBarButtons />
          </div>
        </Toolbar>
      </AppBar>
      {Child && <Child />}
    </Grid>
  )
}

export default NavBar
