import React, { PropsWithChildren, useCallback, useReducer } from 'react'
import { JwtToken } from '../../../types/common'
import { DownloadState, Folder, NewSubtitleState, PermissionType } from '../types'
import jwt_decode from 'jwt-decode'

enum ActionType {
  setCreateDialog = 'set-create-dialog',
  setDeleteDialog = 'set-delete-dialog',
  setEditDialog = 'set-edit-dialog',
  setDeleteFileDialog = 'set-delete-file-dialog',
  setSttState = 'set-stt-state',
  setDownloadState = 'set-download-state',
  setIsLoading = 'set-is-loading',
  setDeleteSessionDialog = 'set-delete-session-dialog',
  addSelectedFolderId = 'add-selected-folder-id',
  removeSelectedFolderId = 'remove-selected-folder-id',
  setSelectedFolders = 'set-selected-folders',
  addExcludeFolderId = 'add-exclude-folder-id',
  removeExcludeFolderId = 'remove-exclude-folder-id',
  setExcludeFolders = 'set-exclude-folders',
  addSelectedDocumentId = 'add-selected-document-id',
  removeSelectedDocumentId = 'remove-selected-document-id',
  setSelectedDocuments = 'set-selected-documents',
  addExcludeDocumentId = 'add-exclude-document-id',
  removeExcludeDocumentId = 'remove-exclude-document-id',
  setExcludeDocuments = 'set-exclude-documents',
    setOriginalDocumentId = 'set-original-document-id',
}

interface Action {
  type: ActionType
  payload?: any
}

type SelectedFolder = {
  id: number
  name: string
}

export type SelectedDocument = {
  id: string
  document_id: string
}

interface State {
  createDialog: boolean
  deleteDialog: boolean
  editDialog: boolean
  deleteFileDialog: boolean
  deleteSessionDialog: boolean
  sttState: NewSubtitleState
  downloadState: DownloadState
  isLoading: boolean
  selectedFolderIds: number[]
  excludedFolderIds: SelectedFolder[]
  selectedDocumentIds: SelectedDocument[]
  excludedDocumentIds: string[]
  originalDocumentId?: string
}

const initialState: State = {
  createDialog: false,
  deleteDialog: false,
  editDialog: false,
  deleteFileDialog: false,
  deleteSessionDialog: false,
  sttState: {
    open: '',
    sessionId: '',
    language: '',
    duration: 0
  },
  downloadState: {
    open: false,
    sessionId: '',
    documentId: '',
    documentType: 'caption'
  },
  isLoading: true,
  selectedFolderIds: [],
  excludedFolderIds: [],
  selectedDocumentIds: [],
  excludedDocumentIds: [],
}

function homeContextReducer(state: State, action: Action) {
  const { type, payload } = action
  switch (type) {
    case ActionType.setCreateDialog:
      return { ...state, createDialog: payload }
    case ActionType.setDeleteDialog:
      return { ...state, deleteDialog: payload }
    case ActionType.setEditDialog:
      return { ...state, editDialog: payload }
    case ActionType.setDeleteFileDialog:
      return { ...state, deleteFileDialog: payload }
    case ActionType.setSttState:
      return { ...state, sttState: payload }
    case ActionType.setDownloadState:
      return { ...state, downloadState: payload }
    case ActionType.setIsLoading:
      return { ...state, isLoading: payload }
    case ActionType.setDeleteSessionDialog:
      return { ...state, deleteSessionDialog: payload }
    case ActionType.addSelectedFolderId:
      return { ...state, selectedFolderIds: [ ...state.selectedFolderIds, payload ] }
    case ActionType.removeSelectedFolderId:
      return { ...state, selectedFolderIds: state.selectedFolderIds.filter(id => id !== payload) }
    case ActionType.setSelectedFolders:
      return { ...state, selectedFolderIds: payload }
    case ActionType.addExcludeFolderId:
      return { ...state, excludedFolderIds: [ ...state.excludedFolderIds, payload ] }
    case ActionType.removeExcludeFolderId:
      return { ...state, excludedFolderIds: state.excludedFolderIds.filter(folder => folder.id !== payload) }
    case ActionType.setExcludeFolders:
      return { ...state, excludedFolderIds: payload }
    case ActionType.addSelectedDocumentId:
      return { ...state, selectedDocumentIds: [ ...state.selectedDocumentIds, payload ] }
    case ActionType.removeSelectedDocumentId:
      return { ...state, selectedDocumentIds: state.selectedDocumentIds.filter(document => document.id !== payload) }
    case ActionType.setSelectedDocuments:
      return { ...state, selectedDocumentIds: payload }
    case ActionType.addExcludeDocumentId:
      return { ...state, excludedDocumentIds: [ ...state.excludedDocumentIds, payload ] }
    case ActionType.removeExcludeDocumentId:
      return { ...state, excludedDocumentIds: state.excludedDocumentIds.filter(id => id !== payload) }
    case ActionType.setExcludeDocuments:
      return { ...state, excludedDocumentIds: payload }
    case ActionType.setOriginalDocumentId:
      return { ...state, originalDocumentId: payload }
    default:
      return state
  }
}

interface IHomeContext {
  state: State
  setCreateDialog: (open: boolean) => void
  setDeleteDialog: (open: boolean) => void
  setEditDialog: (open: boolean) => void
  setDeleteFileDialog: (open: boolean) => void
  setDeleteSessionDialog: (open: boolean) => void
  setIsLoading: (isLoading: boolean) => void
  setSttDialog: (type: '' | 'caption' | 'document') => void
  toggleDownloadDialog: () => void
  setSessionId: (sessionId: string) => void
  hasWriteAccess: (folder: Folder) => boolean
  addSelectedFolderId: (folderId: number) => void
  removeSelectedFolderId: (folder: SelectedFolder) => void
  setSelectedFolders: (folderIds: number[]) => void
  addExcludeFolderId: (folder: SelectedFolder) => void
  removeExcludeFolderId: (folderId: number) => void
  setExcludeFolders: (folderIds: SelectedFolder[]) => void
  addSelectedDocumentId: (document: SelectedDocument) => void
  removeSelectedDocumentId: (documentId: string) => void
  setSelectedDocuments: (documents: SelectedDocument[]) => void
  addExcludeDocumentId: (documentId: string) => void
  removeExcludeDocumentId: (documentId: string) => void
  setExcludeDocuments: (documentIds: string[]) => void
  setOriginalDocumentId: (documentId: string) => void
  removeOriginalDocumentId: () => void,
  setDownloadDocumentType: (type: string) => void
}

export const HomeContext = React.createContext<Partial<IHomeContext>>({
  state: initialState,
  setCreateDialog: () => {},
  setDeleteDialog: () => {},
  setEditDialog: () => {},
  setDeleteFileDialog: () => {},
  setDeleteSessionDialog: () => {},
  setIsLoading: () => {},
  setSttDialog: () => {},
  toggleDownloadDialog: () => {},
  setSessionId: () => {},
  hasWriteAccess: () => false,
  addSelectedFolderId: () => {},
  removeSelectedFolderId: () => {},
  setSelectedFolders: () => {},
  addExcludeFolderId: () => {},
  removeExcludeFolderId: () => {},
  setExcludeFolders: () => {},
  addSelectedDocumentId: () => {},
  removeSelectedDocumentId: () => {},
  setSelectedDocuments: () => {},
  addExcludeDocumentId: () => {},
  removeExcludeDocumentId: () => {},
  setExcludeDocuments: () => {},
  setOriginalDocumentId: () => {},
  removeOriginalDocumentId: () => {},
  setDownloadDocumentType: () => {}
})

export const HomeProvider = ({ children }: PropsWithChildren<any>) => {
  const [ state, dispatch ] = useReducer(homeContextReducer, initialState)

  const setCreateDialog = useCallback((open: boolean) => {
    dispatch({
      type: ActionType.setCreateDialog,
      payload: open
    })
  }, [])

  const setDeleteDialog = useCallback((open: boolean) => {
    dispatch({
      type: ActionType.setDeleteDialog,
      payload: open
    })
  }, [])

  const setEditDialog = useCallback((editMode: boolean) => {
    dispatch({
      type: ActionType.setEditDialog,
      payload: editMode
    })
  }, [])

  const setDeleteFileDialog = useCallback((open: boolean) => {
    dispatch({
      type: ActionType.setDeleteFileDialog,
      payload: open
    })
  }, [])

  const setIsLoading = useCallback((isLoading: boolean) => {
    dispatch({
      type: ActionType.setIsLoading,
      payload: isLoading
    })
  }, [])

  const setSttDialog = useCallback(
    (type: '' | 'caption' | 'document') => {
      dispatch({
        type: ActionType.setSttState,
        payload: {
          ...state.sttState,
          open: type
        }
      })
    },
    [ state.sttState ]
  )

  const toggleDownloadDialog = useCallback(() => {
    dispatch({
      type: ActionType.setDownloadState,
      payload: {
        ...state.downloadState,
        open: !state.downloadState.open
      }
    })
  }, [ state.downloadState ])

  const setSessionId = useCallback(
    (sessionId: string) => {
      dispatch({
        type: ActionType.setDownloadState,
        payload: {
          ...state.downloadState,
          sessionId
        }
      })
      dispatch({
        type: ActionType.setSttState,
        payload: {
          ...state.sttState,
          sessionId
        }
      })
    },
    [ state.sttState, state.downloadState ]
  )

  const setDownloadDocumentType = useCallback(
    (type: string) => {
      dispatch({
        type: ActionType.setDownloadState,
        payload: {
          ...state.downloadState,
          documentType: type
        }
      })
    },
    [  state.downloadState ]
  )

  const hasWriteAccess = (folder: Folder) => {
    const decodedToken = jwt_decode(localStorage.getItem('token') ?? '') as JwtToken
    const roleIsAdmin = decodedToken.scopes.includes('admin')

    return (
      (!roleIsAdmin && !folder) ||
      roleIsAdmin ||
      (folder?.permissions?.[ 0 ]?.permission as PermissionType) === PermissionType.WRITE
    )
  }

  const setDeleteSessionDialog = useCallback((open: boolean) => {
    dispatch({
      type: ActionType.setDeleteSessionDialog,
      payload: open
    })
  }, [])

  const addSelectedFolderId = useCallback((folderId: number) => {
    dispatch({
      type: ActionType.addSelectedFolderId,
      payload: folderId
    })

    dispatch({
      type: ActionType.removeExcludeFolderId,
      payload: folderId
    })
  }, [])

  const removeSelectedFolderId = useCallback((folder: SelectedFolder) => {
    dispatch({
      type: ActionType.removeSelectedFolderId,
      payload: folder.id
    })

    dispatch({
      type: ActionType.addExcludeFolderId,
      payload: folder
    })
  }, [])

  const setSelectedFolders = useCallback((folderIds: number[]) => {
    dispatch({
      type: ActionType.setSelectedFolders,
      payload: folderIds
    })
  }, [])

  const addExcludeFolderId = useCallback((folder: SelectedFolder) => {
    dispatch({
      type: ActionType.addExcludeFolderId,
      payload: folder
    })
  }, [])

  const removeExcludeFolderId = useCallback((folderId: number) => {
    dispatch({
      type: ActionType.removeExcludeFolderId,
      payload: folderId
    })
  }, [])

  const setExcludeFolders = useCallback((folders: SelectedFolder[]) => {
    dispatch({
      type: ActionType.setExcludeFolders,
      payload: folders
    })
  }, [])

  const addSelectedDocumentId = useCallback((document: SelectedDocument) => {
    dispatch({
      type: ActionType.addSelectedDocumentId,
      payload: document
    })

    dispatch({
      type: ActionType.removeExcludeDocumentId,
      payload: document.id
    })
  }, [])

  const removeSelectedDocumentId = useCallback((documentId: string) => {
    dispatch({
      type: ActionType.removeSelectedDocumentId,
      payload: documentId
    })

    dispatch({
      type: ActionType.addExcludeDocumentId,
      payload: documentId
    })
  }, [])

  const setSelectedDocuments = useCallback((documents: SelectedDocument[]) => {
    dispatch({
      type: ActionType.setSelectedDocuments,
      payload: documents
    })
  }, [])

  const addExcludeDocumentId = useCallback((documentId: string) => {
    dispatch({
      type: ActionType.addExcludeDocumentId,
      payload: documentId
    })
  }, [])

  const removeExcludeDocumentId = useCallback((documentId: string) => {
    dispatch({
      type: ActionType.removeExcludeDocumentId,
      payload: documentId
    })
  }, [])

  const setExcludeDocuments = useCallback((documentIds: string[]) => {
    dispatch({
      type: ActionType.setExcludeDocuments,
      payload: documentIds
    })
  }, [])

  const setOriginalDocumentId = useCallback((originalFile: string) => {
    dispatch({
      type: ActionType.setOriginalDocumentId,
      payload: originalFile
    })
  }, [])

  const removeOriginalDocumentId = useCallback(() => {
    dispatch({
      type: ActionType.setOriginalDocumentId,
      payload: undefined
    })
  }, [])

  return (
    <HomeContext.Provider
      value={ {
        state,
        setSttDialog,
        toggleDownloadDialog,
        setCreateDialog,
        setDeleteDialog,
        setEditDialog,
        setDeleteFileDialog,
        setSessionId,
        setIsLoading,
        hasWriteAccess,
        setDeleteSessionDialog,
        addSelectedFolderId,
        removeSelectedFolderId,
        setSelectedFolders,
        addExcludeFolderId,
        removeExcludeFolderId,
        setExcludeFolders,
        addSelectedDocumentId,
        removeSelectedDocumentId,
        setSelectedDocuments,
        addExcludeDocumentId,
        removeExcludeDocumentId,
        setExcludeDocuments,
        setOriginalDocumentId,
        removeOriginalDocumentId,
        setDownloadDocumentType
      } }
    >
      {children}
    </HomeContext.Provider>
  )
}
