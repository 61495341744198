import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = (logoHeight: number) =>
  makeStyles((theme: Theme) => {
    /**
     * Set logo size which is used to calculate all other properties
     * of the logo.
     *   Ratio's
     *     spacing between logo and text 4:1: 36px logo equals 9px space
     *     font size relative to logo:   9:4: 36px logo equals 16px font
     */
    const NAVBAR_HEIGHT = logoHeight

    const LOGO_SIZE = NAVBAR_HEIGHT
    const LOGO_TEXT_RATIO = 5 / 9

    return createStyles({
      logoRoot: {
        display: 'flex',
        userSelect: 'none'
      },
      logo: {
        width: `${ LOGO_SIZE }px`,
        height: `${ LOGO_SIZE }px`,
      },
      defaultLogo: {
        fill: theme.scriptix.logoColor,
      },
      navbarLogo: {
        fill: theme.scriptix.navbarLogoColor,
        width: 62,
        height: 62,
        [ `${ theme.breakpoints.up('xs') } and (orientation: landscape)` ]: {
          width: 46,
          height: 46,
        },
        [ theme.breakpoints.up('sm') ]: {
          width:78,
          height: 48,
        },
      },
      text: {
        fontSize: LOGO_SIZE * LOGO_TEXT_RATIO,
        marginLeft: 9,
        lineHeight: `${ LOGO_SIZE }px`,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        letterSpacing: '0.01em',
      },
      defaultText: {
        color: theme.scriptix.logoTextColor,
      },
      navbarText: {
        color: theme.scriptix.navbarLogoColor,
        lineHeight: '42px',
        fontSize: 42 * LOGO_TEXT_RATIO,
        [ `${ theme.breakpoints.up('xs') } and (orientation: landscape)` ]: {
          lineHeight: '36px',
          fontSize: 36 * LOGO_TEXT_RATIO,

        },
        [ theme.breakpoints.up('sm') ]: {
          lineHeight: '48px',
          fontSize: 48 * LOGO_TEXT_RATIO,
        },
      },
    })
  })
