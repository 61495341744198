import React from 'react'
import { Redirect } from 'react-router-dom'

import { useApiConfig } from 'misc/contexts/ApiConfigContext'
import Navbar from 'components/molecules/Navbar'
import useCurrentUser from 'state/react-query/query/getCurrentUser'
import { AccountMeObjectRolesEnumOptions } from '@scriptix-io/scriptix-api-v3'

interface IProps {
  component: React.ElementType
}

/**
 * Uses the login state to find out whether the current route has the required states.
 */
export const RouteValidator = ({
  component: Component,
}: IProps) => {
  const { loginState } = useApiConfig()

  return (
    loginState
      ? <Navbar Child={ Component } />
      : <Redirect to="/login" />
  )
}

/**
 * Validates and displayes the corresponding UI when not authenticated
 */
export const NonAuthenticationValidator = ({
  component: Component,
}: IProps) => {
  const { loginState } = useApiConfig()

  return (
    loginState
      ? <Redirect to="/" />
      : <Component />
  )
}

export const AdminValidator = ({
  component: Component,
}: IProps) => {
  const { apiConfig } = useApiConfig()
  const currentUser = useCurrentUser(apiConfig)

  return (
    currentUser.data?.roles.indexOf(AccountMeObjectRolesEnumOptions.Sysop) === -1
      ? <Redirect to="/" />
      : <RouteValidator component={ Component } />
  )
}