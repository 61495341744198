import { ClassNameMap } from '@material-ui/styles'
import {
  DocumentType,
  SignupRegistrationInterfaceLearnAboutEnumOptions,
  SignupRegistrationInterfaceProductEnumOptions,
  SpeechToTextSessionStateObjectMediaTypeEnum,
  SpeechtToTextSessionStatusEnum
} from '@scriptix-io/scriptix-api-v3'
import { FormikErrors } from 'formik/dist/types'

export type MuiClassType = {
  className?: string
  classes?: ClassNameMap<string>
}

export type keyInfoListObject = {
  id: number,
  info: string,
  keyIconOne: string,
  keyIconTwo?: string,
  keyIconThree?: string
}

export enum severity {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info'
}

export {
  SignupRegistrationInterfaceProductEnumOptions,
  SignupRegistrationInterfaceLearnAboutEnumOptions
}

export type rowsType = {
  id: number,
  name: string,
  duration: string,
  language: string,
  uploadDate: string,
  status: string,
}

export type optionType = {
  id: number,
  value: string,
  showIcon: boolean,
  Icon?: JSX.Element,
}

export enum colType {
  link = 'link',
  string = 'string',
  button = 'button',
  check = 'check',
  status = 'status',
  keyIcons = 'keyIcons',
  duration = 'duration'
}

export type tableHeader = {
  id: number,
  label: string,
  value: string,
  sort: boolean,
  type: colType,
}

export type tableRowsType = {
  label: string,
  value: string | number | JSX.Element | Date | undefined,
  valueOne?: string,
  valueTwo?: string,
  valueThree?: string
}

export type resultOnSuccess = {
  duration?: number,
  filename?: string,
  media_type?: SpeechToTextSessionStateObjectMediaTypeEnum,
  media_url?: string,
  session_id?: string,
  status: SpeechtToTextSessionStatusEnum,
}

export type newSubVa1ues = {
  filename: string;
  maxLineLength: number;
  maxLineWords: number;
  maxSegmentDuration: number;
  maxSegmentLines: number;
  maxSegmentWords: number;
  maxSilenceBetweenWords: number;
  minSegmentGap: number;
  webhookHeaders: undefined;
  webhookUrl: undefined;
  webhookMethod: string;
}

export type newSubErrors =
  FormikErrors<{
    filename: string;
    maxLineLength: number;
    maxLineWords: number;
    maxSegmentDuration: number;
    maxSegmentLines: number;
    maxSegmentWords: number;
    maxSilenceBetweenWords: number;
    minSegmentGap: number;
    webhookHeaders: undefined;
    webhookUrl: undefined;
    webhookMethod: string;
  }>

export type newSubType = {
  type?: DocumentType,
  values: newSubVa1ues,
  errors: newSubErrors,
  handleChange: {
    (e: React.ChangeEvent<unknown>): void;
    <T_1 = string | React.ChangeEvent<unknown>>(field: T_1): T_1 extends React.ChangeEvent<unknown>
      ? void
      : (e: string | React.ChangeEvent<unknown>) => void;
  },
}

export type JwtToken = {
  oid: number;
  uid: number;
  iat: number;
  exp: number;
  scopes: string[];
};