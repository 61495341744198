import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles<Theme>(theme =>
  createStyles({
    submit: {
      margin: '0% 0% 0% 0%',
      width: '20%',
      textTransform: 'none',
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: '12px',
      '&:hover': {
        background: theme.palette.primary.dark
      }
    },
    secondSubmit: {
      margin: '0% 0% 0% 1%'
    },
    editButton: {
      margin: '0% 0% 0% 1%',
      width: '100%',
      fontWeight: 600,
      fontSize: '12px',
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white'
      }
    },
    buttonContainer: {
      display: 'flex',
      width: '70%',
      marginLeft: '5%',
      marginTop: '4%',
      [ theme.breakpoints.down('md') ]: {
        width: '80%'
      }
    },
    rightContainer: {
      width: '100%',
      paddingLeft: '2%',
      [ theme.breakpoints.down('sm') ]: {
        width: '800px'
      }
    },
    homeContainer: {
      paddingTop: '2em',
      paddingBottom: '2em',
      height: '100%',
      [ theme.breakpoints.up('lg') ]: {
        maxWidth: '100%'
      }
    },
    gridContainer: {
      height: '100%',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      [ theme.breakpoints.down('sm') ]: {
        display: 'block'
      }
    },
    editButtonContain: {
      width: '43%',
      display: 'flex',
      marginLeft: '5%'
    },
    warningStyle: {
      width: '70%',
      margin: '1% 0% 0% 5%',
      '& .MuiAlert-icon': {
        display: 'none'
      },
      [ theme.breakpoints.down('md') ]: {
        width: '90%'
      }
    },
    warningTextStyle: {
      fontSize: '14px'
    },

    tableContainer: {
      marginLeft: '1%',
      borderRadius: '4px',
      border: '1px solid lightgray',
      '& .MuiTableCell-head': {
        fontWeight: 800,
        backgroundColor: 'lightgray'
      },
      '& .MuiTableCell-root': {
        fontSize: '16px',
        textAlign: 'center'
      }
    },
    tableFooter: {
      width: '80%',
      margin: '2%',
      textAlign: 'center',
      [ theme.breakpoints.down('md') ]: {
        width: '90%'
      }
    },
    showMoreButton: {
      width: '20%',
      fontWeight: 600,
      fontSize: '12px'
    },
    showHiddenButton: {
      display: 'none'
    },
    captionLink: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    tableStatus: {
      display: 'flex'
    },
    loaderStyle: {
      paddingRight: '1%'
    },
    fileFolderContainer: {
      padding: '0.5em',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '0.4em'
    },
    breadcrumbContainer: {
      marginTop: '1em'
    },
    headerActions: {
      justifyContent: 'space-between'
    },
    tableHeader: {
      justifyContent: 'space-between',
      backgroundColor: '#E0E0E0',
      minHeight: '45px'
    },
    listItemText: {
      color: 'black'
    },
    listItem: {
      display: 'flex',
      alignItems: 'center'
    },
    listItemCheckboxFolder: {
      marginLeft: '-4px'
    },
    listItemActionButton: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center'
    },
    tableStatusAction: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '14px'
    },
    statusAction: {
      marginLeft: 'auto'
    },
    statusActionFile: {
      paddingRight: '0 !important'
    },
    gridFolderItem: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '0.5em',
      paddingBottom: '0.5em'
    },
    gridFolderContainer: {
      marginTop: '0.5em'
    },
    folderListItemIcon: {
      minWidth: '40px'
    },
    listItemContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    defaultAccessContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    defaultCreateFolderAccessContainer: {
      justifyContent: 'space-between',
      marginTop: '0.5em',
      marginBottom: '0.8em'
    },
    createFolderTitle: {
      paddingBottom: '0 !important',
      width: '400px'
    },
    createFolderGroupAccess: {
      border: '1px solid lightgray',
      padding: '0 1em',
      borderRadius: '4px',
      paddingBottom: '0.3em'
    },
    ctxMenuItem: {
      fontWeight: 'bold'
    },
    circularLoading: {
      display: 'flex',
      justifyContent: 'center',
      height: '70%',
      alignItems: 'center'
    },
    textOverflow: {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    searchIcon: {
      color: 'gray'
    },
    searchFoldersInput: {
      paddingTop: 0
    },
    rowsPerPage: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '0.5em'
    },
    selectRowsPerPage: {
      marginLeft: '0.5em'
    },
    uploadCardContainer: {
      width: 'calc(30% - 10px)',
      [ theme.breakpoints.down('sm') ]: {
        width: '100%'
      },
      [ theme.breakpoints.up('lg') ]: {
        width: 'calc(20% - 10px)'
      }
    },
    folderGridContainer: {
      width: 'calc(70% - 10px)',
      [ theme.breakpoints.down('sm') ]: {
        width: '100%'
      },
      [ theme.breakpoints.up('lg') ]: {
        width: 'calc(80% - 10px)'
      }
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 !important'
    },
    checkboxHeader: {
      marginLeft: '0.7em'
    },
    moveButton: {
      color: 'gray'
    },
    deleteButton: {
      color: 'red'
    },
    dragItemInfo: {
      position: 'absolute',
      bottom: -5,
      left: '100px',
      color: 'black'
    },
    checkboxFolder: {
      padding: '5px !important',
      marginRight: '0.4em'
    },
    folderFileName: {
      marginLeft: '1.2em'
    },
    tableHeaderText: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center'
    },
    tableHeaderNameField: {
      paddingLeft: '2em !important'
    },
    statusChip: {
      color: 'white'
    },
    showMultipleFiles: {
      maxHeight: '120px',
      overflow: 'auto'
    },
    breadcrumb: {
      fontWeight: 'bold'
    },
    centerUploadView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadTranscriptView: {
      marginTop: '1em',
    },
    advancedUploadHeader: {
      justifyContent: 'space-between',
    }
  })
)
