import base from '@scriptix/config/theme'

export const themeColors = {
  ...base,
  white: '#fff',
}

export const themeFonts = {
  metroPolis: 'metropolis',
  sansSerif: 'sans-serif',
  lato: 'Lato',
  monospace: 'monospace'
}
