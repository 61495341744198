import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { SCRIPTIX_DOCUMENTATION } from 'misc/urls'

import HelpIcon from '@material-ui/icons/Help'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'

import { useHistory } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import NavBarAppMenu from '../AppMenu'
import { useApiConfig } from 'misc/contexts/ApiConfigContext'
import { logout } from 'state/react-query/query/authentication'
import { useTranslation } from 'react-i18next'
import useCurrentUser from 'state/react-query/query/getCurrentUser'
import CustomButton from '../../../../atoms/Button'

type NavigationTargets = 'settings' | 'logout' | 'docs'

const useStyles = makeStyles((theme) =>
  createStyles({
    listItemIcon: {
      minWidth: 32,
    },
    menuItemStyle: {
      '&.MuiMenuItem-root': {
        fontSize: '14px',
        fontWeight: 600,
        '&:hover': {
          background: theme.palette.primary.main,
          color: 'white',
          borderRadius: '4px',
        },
        '&:hover $listItemIcon': {
          color: 'white'
        }
      },
    },
  })
)
const NavBarButtons = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const { apiConfig, setBearerToken, setLoginState } = useApiConfig()

  const currentUser = useCurrentUser(apiConfig)

  const handleNavigation = (option: NavigationTargets, menuClose?: boolean) => {
    if (menuClose) handleMenuClose()
    switch (option) {
      case 'logout': {
        // Clear out bearer token, this will reacreate the ApiConfig and consequently checks if
        // we are still logged in
        const internalLogout = () => {
          setBearerToken(undefined)
          setLoginState(false)
          history.replace('/login')
        }

        logout(apiConfig).finally(internalLogout)
        setTimeout(() => window.location.reload(), 300)

        break
      }
      case 'docs': {
        window.open(SCRIPTIX_DOCUMENTATION, '_blank')
        break
      }
      case 'settings': {
        history.push('/settings')
        break
      }
    }
  }

  const menuId = 'primary-account-menu'
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => setAnchorEl(null)

  return (
    <>
      <CustomButton
        variant={ 'text' }
        onClick={ () => window.location.href = 'https://scriptix.app' }
        buttonContent={ 'Go to the new portal' }
      />
      
      <NavBarAppMenu backoffice={ currentUser.data?.roles.indexOf('sysop') !== -1 } />
      <IconButton color="inherit" onClick={ () => handleNavigation('docs') }>
        <HelpIcon />
      </IconButton>
      <IconButton
        color="inherit"
        aria-label="account of current user"
        aria-controls={ menuId }
        aria-haspopup="true"
        onClick={ handleMenuOpen }
        edge="end"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
        id={ menuId }
        keepMounted
        transformOrigin={ { vertical: 'bottom', horizontal: 'right' } }
        open={ isMenuOpen }
        onClose={ handleMenuClose }
      >
        <MenuItem className={ classes.menuItemStyle } onClick={ () => handleNavigation('settings', true) }>
          <ListItemIcon className={ classes.listItemIcon }>
            <AccountCircle />
          </ListItemIcon>
          <Typography>{ t('settings') }</Typography>
        </MenuItem>
        <MenuItem className={ classes.menuItemStyle } onClick={ () => handleNavigation('logout', true) }>
          <ListItemIcon className={ classes.listItemIcon }>
            <ExitToAppIcon />
          </ListItemIcon>
          <Typography>{ t('logout') }</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default NavBarButtons
