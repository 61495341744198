import { useQuery } from 'react-query'
import { AccountApi, AccountOrganizationObject } from '@scriptix-io/scriptix-api-v3'
import { ApiConfiguration, useApiConfig } from 'misc/contexts/ApiConfigContext'
import i18n from 'i18next'

const getCurrentOrganization = async (config: ApiConfiguration): Promise<AccountOrganizationObject> => {
  try {
    const api = new AccountApi(config)
    return (await api.getOrganization()).result
  } catch (e) {
    throw new Error((e instanceof Error) ? e.message : i18n.t('errorWhileGettingOrganisationCheckLogs'))
  }
}

const fetchCurrentOrganization = (config: ApiConfiguration) => async () => {
  const res = await getCurrentOrganization(config)
  if (!res) throw new Error(i18n.t('problemFetchingTheCurrentOrganizationData'))
  return res
}

const useCurrentOrganization = (config?: ApiConfiguration, enabled?: boolean) => {
  const { apiConfig } = useApiConfig()
  if (!config) {
    config = apiConfig
  }

  return useQuery([ 'currentOrganization' ], fetchCurrentOrganization(config), {
    staleTime: Infinity, enabled: enabled })
}

export default useCurrentOrganization
