import React, { useEffect, useState } from 'react'
import { Dashboard } from '@uppy/react'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/drop-target/dist/style.css'
import '@uppy/status-bar/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/audio/dist/style.css'
import '@uppy/screen-capture/dist/style.css'
import '@uppy/webcam/dist/style.css'
import Uppy from '@uppy/core'

type Props = {
    setEnableFiles: (enable: boolean) => void;
    width?: number;
    height?: number;
    note?: string;
    setUploading: (start: boolean) => void;
    uppy: Uppy;
}

const UppyDashboard = ({
  setEnableFiles,
  width = 450,
  height = 450,
  note,
  setUploading,
  uppy,
}: Props) => {
  const [ filesChanged, setFilesChanged ] = useState<boolean>(false)

  uppy.on('file-removed', () => {
    setFilesChanged(true)
    setUploading(false)
  })

  useEffect(() => {
    const items = uppy.getFiles()
    if (items.length > 0) {
      setEnableFiles(true)
    } else {
      setEnableFiles(false)
    }
    
    if (filesChanged)
      setFilesChanged(false)
  }, [ filesChanged ])

  return (
    <Dashboard
      uppy={ uppy }
      width={ width }
      height={ height }
      target={ '#drag-drop-area' }
      plugins={ [ 'Webcam', 'Audio', 'ScreenCapture' ] }
      proudlyDisplayPoweredByUppy={ false }
      showProgressDetails={ true }
      hideUploadButton={ true }
      locale={ {
        strings: {
          dropPasteImportFiles: note ? note : 'Drop files here, %{browseFiles} or import from:',
        }
      } }
    />
  )
}

export default UppyDashboard
