import React, { memo } from 'react'
import Button from '@material-ui/core/Button'

import { MuiClassType } from 'types/common'
import { Link as RouterLink } from 'react-router-dom'

type IProps = {
  color?: 'primary' | 'secondary' | 'inherit'
  variant?: 'contained' | 'outlined' | 'text'
  fullWidth?: boolean
  buttonContent: string
  type?: 'submit' | 'button'
  disabled?: boolean
  onClick?: () => void
  size?: 'large' | 'medium' | 'small'
  endIcon?: JSX.Element
  startIcon?: JSX.Element
  to?: string
}

/**
 * Customisable and Reusable Button Component
 */
const CustomButton = ({
  color,
  variant,
  fullWidth,
  buttonContent,
  type,
  classes = {},
  disabled,
  onClick,
  size,
  endIcon,
  startIcon,
  to
}: IProps & MuiClassType) => {
  const props = {
    color,
    variant,
    fullWidth,
    type,
    disabled,
    onClick,
    size,
    endIcon,
    startIcon,
    className: classes.classes
  }
  if (to)
    return (
      <Button component={ RouterLink } to={ to } { ...props }>
        { buttonContent }
      </Button>
    )

  return <Button { ...props }>{ buttonContent }</Button>
}

export default memo(CustomButton)
