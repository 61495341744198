import React, { memo } from 'react'
import clsx from 'clsx'

import { ReactComponent as ScriptixIconLogo } from '@scriptix/assets/icons/scriptix-logo.svg'
import { useStyles } from './style'
import { useApiConfig } from '../../../misc/contexts/ApiConfigContext'
import useCurrentOrganization from '../../../state/react-query/query/getCurrentOrganization'

type LogoVariant = 'navbar' | 'default'
type LogoComponent = 'Logo' | 'Text'

interface ScriptixLogoInterface {
  logoHeight?: number;
  variant?: LogoVariant;
}

const makeVariant = (variant: LogoVariant, component: LogoComponent) =>
  (variant + component) as `${ LogoVariant }${ LogoComponent }`

const ScriptixLogo = ({
  variant = 'default',
  logoHeight = 60,
}: ScriptixLogoInterface) => {
  const classes = useStyles(logoHeight)()
  const { apiConfig } = useApiConfig()
  const currentOrganization = useCurrentOrganization(apiConfig, !!apiConfig?.accessToken?.())

  return (
    <div className={ classes.logoRoot } >
      {
        currentOrganization?.data?.resellerBranding?.brandLogo ?
          <img
            src={ currentOrganization?.data?.resellerBranding?.brandLogo }
            alt='logo'
            className={ clsx(classes.logo, classes[ makeVariant(variant, 'Logo') ]) }
          /> : <ScriptixIconLogo
            className={
              clsx(classes.logo, classes[ makeVariant(variant, 'Logo') ])
            }
          />
      }

      <span className={ clsx(classes.text, classes[ makeVariant(variant, 'Text') ]) }>
        {
          !currentOrganization?.data?.resellerBranding?.brandLogo && <span><strong>script</strong>ix</span>
        }
      </span>
    </div>
  )
}

export default memo(ScriptixLogo)
