import { useQuery } from 'react-query'
import {
  SpeechToTextApi,
  SpeechToTextLanguageListResponse,
  GetSpeechToTextLanguagesRequest
} from '@scriptix-io/scriptix-api-v3'
import { ApiConfiguration, useApiConfig } from 'misc/contexts/ApiConfigContext'
import i18n from 'i18next'

const getLanguages = async (config: ApiConfiguration): Promise<SpeechToTextLanguageListResponse> => {
  try {
    const speechToTextAPI = new SpeechToTextApi(config)
    const requestParameters: GetSpeechToTextLanguagesRequest = { offset: 0 }
    return await speechToTextAPI.getSpeechToTextLanguages(requestParameters)
  } catch (e) {
    throw new Error(e instanceof Error ? e.message : i18n.t('errorWhileListingSupportedLanguagesCheckLogs'))
  }
}
const fetchLang = (config: ApiConfiguration) => async () => {
  const res = await getLanguages(config)
  if (!res) throw new Error(i18n.t('problemFetchingTheLanguages'))
  return res
}

const useLanguages = () => {
  const { apiConfig } = useApiConfig()

  return useQuery([ 'fetchLang' ], fetchLang(apiConfig), { staleTime: 3600 * 1000 })
}

export { useLanguages }
