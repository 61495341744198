import React from 'react'
import ReactDOM from 'react-dom'
import Slide, { SlideProps } from '@material-ui/core/Slide'
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import { severity } from 'types/common'
import { notificationRoot } from 'misc/domNodes'
import useStyles from './style'

type TProps = {
  message: string;
  severity: severity;
  open : boolean;
  handleClose: () => void,
  snackbarDirection?: SnackbarOrigin
}

function Transition(props: JSX.IntrinsicAttributes & SlideProps) {
  return <Slide { ...props } direction="left" />
}

/**
 * MUI's Snackbar also used as a notification component
 */
const CustomSnackbar = ({
  message,
  severity,
  open,
  handleClose,
  snackbarDirection = { vertical: 'top', horizontal: 'right' }
}: TProps) => {
  const localClasses = useStyles()
  return notificationRoot ? ReactDOM.createPortal(
    <>
      <Snackbar
        open={ open }
        autoHideDuration={ 10000 }
        onClose={ handleClose }
        anchorOrigin={ snackbarDirection }
        TransitionComponent={ Transition }
        key={ `${ message }-${ severity }` }
      >
        <Alert onClose={ handleClose } severity={ severity } classes={ localClasses } variant="standard">
          { message }
        </Alert>
      </Snackbar>
    </>,
    notificationRoot
  ) : null
}

export default CustomSnackbar
