import { useQuery } from 'react-query'
import { AccountApi, AccountMeObject } from '@scriptix-io/scriptix-api-v3'
import { ApiConfiguration } from 'misc/contexts/ApiConfigContext'
import i18n from 'i18next'

const getUser = async (apiConfig: ApiConfiguration): Promise<AccountMeObject> => {
  try {
    const documentAPI = new AccountApi(apiConfig)
    return (await documentAPI.getMe()).result
  } catch (e) {
    throw new Error((e instanceof Error) ? e.message : i18n.t('errorWhileGettingYourDetailsCheckLogs!'))
  }
}

const fetchUser = (apiConfig: ApiConfiguration) => async () => {
  const res = await getUser(apiConfig)
  if (!res) throw new Error(i18n.t('problemFetchingTheCurrentUserData'))
  return res
}

const useCurrentUser = (apiConfig: ApiConfiguration) => {
  return useQuery([ 'fetchUser' ], fetchUser(apiConfig), { staleTime: Infinity })
}

export default useCurrentUser
