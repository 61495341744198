import { severity } from 'types/common'
import { createStore } from './store'

export type NotificationConfig = {
  // open: boolean;
  message: string
  severity: severity
  autoClose?: boolean
  autoCloseDelay?: number
}

const [ notificationStore, notificationStoreWrite ] = createStore<NotificationConfig[]>([])

const pushNotification = (message: NotificationConfig) => {
  notificationStoreWrite(
    current => [ ...current, message ]
  )
  return message
}
const popNotification = (message: NotificationConfig) => {
  notificationStoreWrite(
    current => current.filter(n => n !== message)
  )
}

const useNotificationStore = () => {
  const [ notifications, setNotifications ] = notificationStore()

  return { notifications, pushNotification, popNotification, setNotifications }
}
export default useNotificationStore
export { pushNotification, popNotification }