import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    toolbarLeft: {
      flexBasis: 0,
      flexGrow: 1,
      cursor: 'pointer'
    },
    toolbarCenter: {
      flexGrow: 0
    },
    toolbarRight: {
      flexBasis: 0,
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end'
    },
    logo: {
      fill: '#ffffff'
    },
    announcement: {
      backgroundColor: theme.palette.primary.dark,
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '0.8rem',
      padding: theme.spacing(0.25),
      '& a': {
        color: '#fff'
      }
    }
  })
)
