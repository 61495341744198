import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

import useStyles from './style'
import { MuiClassType } from 'types/common'

/**
 * MUI's standard linear loader
 */
const LinearLoader = ({ classes = {} }: MuiClassType) => {
  const localClasses = useStyles()
  return (
    <div className={ localClasses.root }>
      <LinearProgress variant="query" classes={ classes } />
    </div>
  )
}

export default LinearLoader
