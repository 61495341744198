import React from 'react'
import { Configuration, ResponseContext } from '@scriptix-io/scriptix-api-v3/runtime'
import { AccountApi, AuthenticationApi } from '@scriptix-io/scriptix-api-v3'

/**
 * Default application configuration
 */
const DEFAULT_APP_CONFIG: AppConfig = {
  api: '/api/v3',
  baseUrl: 'api.scriptix.io',
  maxFileSize: 10737418240,
}

/**
 * HTTP Code for unauthorized
 */
const HTTP_UNAUTHORIZED = 401

/**
 * Load API configuration from specified URL, defaults to '/config/config.json'
 * @param location
 * @returns
 */
const loadConfig = async (location = '/config/config.json'): Promise<AppConfig> => {
  const response = await fetch(location)
  return await response.json()
}

/**
 * API Config context with default settings
 */
const ApiConfigContext = React.createContext<ApiConfigContextInterface>({
  appConfig: DEFAULT_APP_CONFIG,
  apiConfig: new Configuration(),
  bearerToken: undefined,
  setBearerToken: () => void 0,
  setLoginState: () => void 0,
  loginState: false
})

/**
 * useApiConfig shortcut to Rect.useContext, to be used as hook when appropriate
 * @returns
 */
const useApiConfig = () => React.useContext(ApiConfigContext)

/**
 * API Provider Config provider
 */
const ApiConfigProvider: React.FC<ApiConfigProviderProps> = ({ children, configUrl, loaderComponent, loginUrl }) => {
  const [ apiConfig, setApiConfig ] = React.useState<Configuration | undefined>(undefined)
  const [ appConfig, setAppConfig ] = React.useState<AppConfig>(DEFAULT_APP_CONFIG)
  const [ bearerToken, setBearerToken ] = React.useState(localStorage.getItem('token') || undefined)
  const [ hasBearerToken, setHasBearerToken ] = React.useState(Boolean(bearerToken))
  const [ error, setError ] = React.useState(false)
  const [ loading, setLoading ] = React.useState(true)
  const [ loginState, setLoginState ] = React.useState<boolean | undefined>()

  // Reload application config if configUrl is changed
  React.useEffect(() => {
    loadConfig(configUrl)
      .then(newConfig => setAppConfig(() => newConfig))
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [ configUrl ])

  React.useEffect(() => {
    // Sync bearer token with sessionStorage to gracefully handle page refreshes
    bearerToken ? localStorage.setItem('token', bearerToken) : localStorage.removeItem('token')

    // Set bearer token configuration state
    setHasBearerToken(Boolean(bearerToken))
  }, [ bearerToken ])

  // Interceptor to use as middleware
  const interceptUnauthorized = React.useCallback(
    (context: ResponseContext) =>
      new Promise<Response>((resolve, reject) => {
        if (context.response.status === HTTP_UNAUTHORIZED) {
          // Clear bearer token
          if (bearerToken) setBearerToken(undefined)

          // Redirect to login url if specified
          if (loginUrl) window.location.replace(loginUrl)

          // Reject the promise
          reject()
        } else {
          resolve(context.response)
        }
      }),
    [ loginUrl, bearerToken ]
  )

  // Update API config once the appConfig changes
  React.useEffect(() => {
    // Determine basepath from either environment (devmode) of config file
    const basePath = process.env.REACT_APP_SCRIPTIX_BASE_URL || appConfig.api

    setApiConfig(
      new Configuration({
        basePath: basePath,
        credentials: 'include',
        accessToken: bearerToken,
        middleware: [
          {
            post: interceptUnauthorized
          }
        ]
      })
    )
  }, [ appConfig, bearerToken, interceptUnauthorized ])

  // Update login state once API config updates
  React.useEffect(() => {
    if (!loading && apiConfig) {
      const api = new AccountApi(apiConfig)
      api
        .getMe()
        .then(() => setLoginState(true))
        .catch(() => setLoginState(false))
    }
  }, [ loading, apiConfig ])

  React.useEffect(() => {
    // Create an refresh timer to update the bearer token
    // if we are logged in with a bearer token

    let hasRefreshSupport = true

    if (loginState && hasBearerToken) {
      const api = new AuthenticationApi(apiConfig)
      const INTERVAL = 15 * 60 * 1000 // 15 minutes

      const timer = window.setInterval(() => {
        if (!hasRefreshSupport) {
          return
        }

        api.refreshToken()
          .then(response => {
            if (response.accessToken) {
              setBearerToken(response.accessToken)
            } else {
              console.warn('Did not receive a new bearer token from refresh token')
            }
          })
          .catch((error: Response) => {
            if (error.status === 404) {
              console.warn('Backend does not support refresh, disabling timer')
              hasRefreshSupport = false
            }
          })
      }, INTERVAL)

      // Clear the timer if anything changes
      return () => {
        window.clearInterval(timer)
      }
    }
  }, [ apiConfig, loginState, hasBearerToken ])

  // Show error, we cannot work without the configuration
  if (error) {
    return <>Something went wrong during loading of the configuration, please try again or contact support.</>
  }

  // Wait for loading to be done and apiConfig to be set. Otherwise return loaderComponent or null. Otherwise it may
  // be possibel that a request is send to the wrong API, which in turn may be intercepted by the middleware.
  //
  // Without an apiConfig, not doing this, may be a recipe for disaster!
  if (loading || !apiConfig || loginState === undefined) {
    return loaderComponent ? <>{ loaderComponent }</> : null
  }

  // Return children nested in a Provider. So children can use the apiConfig when needed
  return (
    <ApiConfigContext.Provider value={
      { appConfig, apiConfig, bearerToken, setBearerToken, loginState, setLoginState }
    }>
      { children }
    </ApiConfigContext.Provider>
  )
}

interface AppConfig {
  api: string
  baseUrl: string
  maxFileSize: number
}
export interface ApiConfigContextInterface {
  appConfig: AppConfig
  apiConfig: Configuration
  bearerToken: string | undefined
  setBearerToken: (token: string | undefined) => void
  readonly loginState: boolean
  setLoginState: (state: boolean) => void
}
interface ApiConfigProviderProps {
  loginUrl?: string
  configUrl?: string
  loaderComponent?: React.ReactElement
}

export { useApiConfig, ApiConfigProvider }
export { Configuration as ApiConfiguration }
