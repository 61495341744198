import {
  AuthenticationApi,
  AuthenticationRegistrationApi,
  AuthForgotPasswordResponse,
  AuthResetPasswordBody,
  ForgotPasswordRequest,
  LoginLoginTypeEnumOptions,
  LoginRequest,
  ResetPasswordRequest,
  SignupRegistrationInterface,
} from '@scriptix-io/scriptix-api-v3'
import { ApiConfiguration } from 'misc/contexts/ApiConfigContext'

const login = async (
  apiConfig: ApiConfiguration,
  setBearerToken: (token: string) => void,
  { username, password }: LoginRequest
) => {
  const authenticationApi = new AuthenticationApi(apiConfig)
  const response = await authenticationApi.login({
    username, password, loginType: LoginLoginTypeEnumOptions.Token
  })
  if (response.accessToken) setBearerToken(response.accessToken)
}

const logout = async (
  apiConfig: ApiConfiguration,
) => {
  const authenticationApi = new AuthenticationApi(apiConfig)
  await authenticationApi.logout()
}

const forgotPassword = async (apiConfig: ApiConfiguration, email: string): Promise<AuthForgotPasswordResponse> => {
  const authenticationApi = new AuthenticationApi(apiConfig)
  const forgotPassReq: ForgotPasswordRequest = {
    authForgotPasswordInterface: { email }
  }
  const response = await authenticationApi.forgotPassword(forgotPassReq)
  return response
}

const resetPassword = async (
  apiConfig: ApiConfiguration,
  { email, password, resetKey }: AuthResetPasswordBody
): Promise<void> => {
  const authenticationApi = new AuthenticationApi(apiConfig)
  const resetPasswordReq: ResetPasswordRequest = {
    authResetPasswordBody: { email, password, resetKey }
  }
  await authenticationApi.resetPassword(resetPasswordReq)
}

const registrationSignup = async (apiConfig: ApiConfiguration, data: SignupRegistrationInterface): Promise<string> => {
  const registrationAPI = new AuthenticationRegistrationApi(apiConfig)
  const regRequest = { signupRegistrationInterface: data }
  const register = await registrationAPI.register(regRequest)
  return register.result.mollieCheckoutUrl
}

export { login, logout, forgotPassword, resetPassword, registrationSignup }
