import { createTheme } from '@material-ui/core/styles'
import { themeColors, themeFonts } from './default'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    drawer: {
      width: number
    }
    scriptix: {
      blue: string
      navbarLogoColor: string
      navbarLogoTextColor: string
      logoColor: string
      logoTextColor: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    drawer?: {
      width?: number
    }
    scriptix?: {
      blue?: string
      navbarLogoColor?: string
      navbarLogoText?: string
      logoColor?: string
      logoTextColor?: string
    }
  }
}

const theme = (primaryColor?: string)  =>  {
  const primary = primaryColor ? primaryColor : themeColors.scriptixBaseBlue
  return createTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            height: '100%'
          },
          body: {
            height: '100%',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              width: '0.9em'
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              height: '50px',
              border: '6px solid transparent',
              borderRadius: '15px'
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: '#888'
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#888'
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555'
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: '#888'
            }
          }
        }
      }
    },
    palette: {
      primary: {
        main: primary,
        contrastText: '#ffffff'
      },
      error: {
        main: themeColors.scriptixBaseRed
      },
      success: {
        main: themeColors.scriptixBaseGreen
      },
      tonalOffset: 0.3,
      contrastThreshold: 3,
      type: 'light'
    },
    typography: {
      button: {
        textTransform: 'none'
      },
      fontFamily: [ themeFonts.metroPolis, themeFonts.sansSerif ].join(',')
    },
    scriptix: {
      blue: primary,
      navbarLogoColor: '#ffffff',
      navbarLogoText: '#ffffff',
      logoColor: primary,
      logoTextColor: '#000000'
    },
    drawer: {
      width: 240
    }
  })
}

export default theme
