import React, { memo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import AppsIcon from '@material-ui/icons/Apps'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'

import DescriptionIcon from '@material-ui/icons/Description'
import CodeIcon from '@material-ui/icons/Code'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import PostAddIcon from '@material-ui/icons/PostAdd'

import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'

interface Props {
  /**
   * Define when to show the appmenu button.
   *   always - Show always, even if there is nothing to show
   *   conditional - Show if at least two options are visible
   */

  show?: 'conditional' | 'always'
  /**
   * Show Speech to text (transcription & subtitling) option
   */
  stt?: boolean
  /**
   * Show reseller option
   */
  reseller?: boolean
  /**
   * Show machine learning optin
   */
  machineLearning?: boolean
  /**
   * Show backoffice option
   */
  backoffice?: boolean
  /**
   * Show custom model option
   */
  customModel?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: theme.zIndex.appBar
    },
    paper: {
      padding: theme.spacing(1),
      position: 'sticky'
    },
    icon: {
      backgroundColor: theme.palette.primary.main
    }
  })
)

export const NavBarAppMenu = ({
  show = 'conditional',
  stt = true,
  reseller = false,
  machineLearning = false,
  backoffice = false,
  customModel = true
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const open = !!anchorEl

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(current => (current ? null : event.currentTarget))
  }

  /**
   * Return empty element if we don't have at least two options to show
   */
  if (show !== 'always' && [ stt, reseller, machineLearning, backoffice, customModel ].filter(x => x === true).length < 2) {
    return <></>
  }

  const navigateTo = (dest: string) => {
    history.push(dest)
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton color='inherit' onClick={ handleClick }>
        <AppsIcon/>
      </IconButton>
      <Popper className={ classes.popper } open={ open } anchorEl={ anchorEl } placement='bottom-end' transition>
        <Paper className={ `${ classes.paper }` }>
          <List>
            {stt && (
              <ListItem button onClick={ () => navigateTo('/') }>
                <ListItemAvatar>
                  <Avatar className={ classes.icon }>
                    <DescriptionIcon/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={ t('Subtitling and Transcription') }
                  // secondary="Process your files into beautifull captions and documents"
                />
              </ListItem>
            )}
            {reseller && (
              <ListItem button>
                <ListItemAvatar>
                  <Avatar className={ classes.icon }>
                    <CodeIcon/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ t('Reseller') }/>
              </ListItem>
            )}
            {machineLearning && (
              <ListItem button>
                <ListItemAvatar>
                  <Avatar className={ classes.icon }>
                    <SupervisorAccountIcon/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ t('Machine learning') }/>
              </ListItem>
            )}
            {backoffice && (
              <ListItem button onClick={ () => navigateTo('/backoffice') }>
                <ListItemAvatar>
                  <Avatar className={ classes.icon }>
                    <BusinessCenterIcon/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ t('Backoffice') }/>
              </ListItem>
            )}
            {customModel && (
              <ListItem button onClick={ () => navigateTo('/custom-models') }>
                <ListItemAvatar>
                  <Avatar className={ classes.icon }>
                    <PostAddIcon/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ t('Custom Models') }/>
              </ListItem>
            )}
          </List>
        </Paper>
      </Popper>
    </>
  )
}

export default memo(NavBarAppMenu)
