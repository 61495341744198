import React from 'react'
import axios, { AxiosInstance } from 'axios'

import { useApiConfig } from 'misc/contexts/ApiConfigContext'

const buildAxiosInstance = (basePath: string, bearerToken: undefined | string = undefined) => {
  const headers: { [ key: string ]: string } = {}
  if (bearerToken) {
    headers[ 'Authorization' ] = `Bearer ${ bearerToken }`
  }
  const instance = axios.create({
    baseURL: basePath,
    withCredentials: true,
    headers: {
      ...headers
    },
    responseType: 'json',
  })
  
  return instance
}
const useAxios = () => {
  const { apiConfig, bearerToken, setBearerToken } = useApiConfig()
  const [ instance, setInstance ] = React.useState<AxiosInstance>(
    () => buildAxiosInstance(apiConfig.basePath, bearerToken)
  )

  React.useEffect(() => {
    setInstance(() => buildAxiosInstance(apiConfig.basePath, bearerToken))
  }, [ apiConfig, bearerToken ])

  instance.interceptors.response.use(response => {
    return response
  }, error => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        if (bearerToken) {
          setBearerToken(undefined)
        }
      }
    }
    return Promise.reject(error)
  })

  return instance
}

export default useAxios