import { BaseError } from 'api'
import { AxiosError } from 'axios'
import useAxios from 'misc/hooks/useAxios'
import { useMutation, useQuery } from 'react-query'
import queryClient from '../../../state/react-query'
import {
  BatchDeleteRequest,
  FolderDocumentListResponse,
  FolderRequest,
  FolderResponse,
  FolderUpdateRequest
} from '../types'
import { useQuery as queryParams } from '../../../misc/hooks/use-query'

/**
 * List all available folders
 * @param options
 * @returns
 */
export const useFolders = (options?: { limit?: number; offset?: number; enabled?: boolean; query?: string }) => {
  const axios = useAxios()

  const fetch = async () => {
    const { data } = await axios.get<FolderDocumentListResponse>('/folders', {
      params: {
        limit: options?.limit || 25,
        offset: options?.offset || 0,
        filter: options?.query ? `name:like:%${ options!.query }%` : undefined
      }
    })
    return data
  }
  return useQuery<FolderDocumentListResponse, AxiosError<BaseError>>([ 'folders', options ], fetch, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: options?.enabled,
  })
}

export const useFolder = (
  folderId: number,
  options?: { limit?: number; offset?: number; enabled?: boolean; query?: string }
) => {
  const axios = useAxios()

  const fetch = async () => {
    const { data } = await axios.get<FolderResponse>(`/folders/${ folderId }`, {
      params: {
        limit: options?.limit || 25,
        offset: options?.offset || 0,
        filter: options?.query ? `name:like:%${ options!.query }%` : undefined
      }
    })
    return data
  }
  return useQuery<FolderResponse, AxiosError<BaseError>>([ 'folder', folderId, options ], fetch, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: folderId > -1,
  })
}

export const useCreateFolder = () => {
  const axios = useAxios()
  const fId = queryParams().get('f')
  const folderId = parseInt(fId ?? '', 10)

  const mutate = async (payload: FolderRequest) => {
    const { data } = await axios.post<FolderResponse>('/folders', payload)
    return data
  }
  return useMutation<FolderResponse, AxiosError<BaseError>, FolderRequest, unknown>(mutate, {
    onSettled: () => {
      if (!isNaN(folderId)) {
        queryClient.invalidateQueries([ 'folder', folderId ])
      } else {
        queryClient.invalidateQueries([ 'folders' ])
      }
    }
  })
}

export const useUpdateFolder = (folderId: number) => {
  const axios = useAxios()

  const mutate = async (payload: FolderRequest) => {
    const { data } = await axios.put<FolderResponse>(`/folders/${ folderId }`, payload)
    return data
  }
  return useMutation<FolderResponse, AxiosError<BaseError>, FolderRequest, unknown>(mutate, {
    onSettled: data => {
      queryClient.invalidateQueries([ 'folder', data?.result?.parent_id ])
      queryClient.invalidateQueries([ 'folders' ])
    }
  })
}

export const useUpdateFolderParent = () => {
  const axios = useAxios()
  const fId = queryParams().get('f')
  const folderId = parseInt(fId ?? '', 10)

  const mutate = async (payload: FolderUpdateRequest) => {
    await axios.patch<void>('/folders/update-parent', payload)
  }
  return useMutation<void, AxiosError<BaseError>, FolderUpdateRequest, unknown>(mutate, {
    onSettled: () => {
      if (!isNaN(folderId)) {
        queryClient.invalidateQueries([ 'folder', folderId ])
      } else {
        queryClient.invalidateQueries([ 'folders' ])
      }
    }
  })
}

export const useDeleteBatch = () => {
  const axios = useAxios()
  const fId = queryParams().get('f')
  const folderId = parseInt(fId ?? '', 10)

  const mutate = async (payload: BatchDeleteRequest) => {
    await axios.patch<void>('/folders/delete-batch', payload)
  }
  return useMutation<void, AxiosError<BaseError>, BatchDeleteRequest, unknown>(mutate, {
    onSettled: () => {
      if (!isNaN(folderId)) {
        queryClient.invalidateQueries([ 'folder', folderId ])
      } else {
        queryClient.invalidateQueries([ 'folders' ])
      }
    }
  })
}

export const useDeleteFolder = () => {
  const axios = useAxios()
  const fId = queryParams().get('f')
  const folderId = parseInt(fId ?? '', 10)

  const mutate = async (folderId: number) => {
    const { data } = await axios.delete<void>(`/folders/${ folderId }`)
    return data
  }

  return useMutation<void, AxiosError<BaseError>, number, unknown>(mutate, {
    onSettled: () => {
      if (!isNaN(folderId)) {
        queryClient.invalidateQueries([ 'folder', folderId ])
      } else {
        queryClient.invalidateQueries([ 'folders' ])
      }
    }
  })
}
