import * as yup from 'yup'
import i18n from 'i18n'

const MAX_FILE_SIZE = 2000000

const validationSchema = yup.object({
  language: yup
    .string()
    .required(i18n.t('languageIsRequired')),
  file: yup
    .mixed()
    .required(i18n.t('youNeedToProvideAFile'))
    .test(
      'fileSize',
      i18n.t('fileTooLarge'),
      value => value && value.size <= MAX_FILE_SIZE
    )
    .test('type', i18n.t('onlyAudioAndVideoFileIsAccepted'), (value: File[]) => {
      return value && (
        value[ 0 ].type === 'audio/*' ||
        value[ 0 ].type === 'video/*'
      )
    }),
  type: yup.string(),
  punctuations: yup.boolean(),
  filename: yup.string().required(i18n.t('fileIsRequired'))

})

export default validationSchema
