import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ReactQueryDevtools } from 'react-query/devtools'
import RootRoute from './routes/Root'
import theme from './theme'
import LogRocket from 'logrocket'
import './index.scss'
import { HomeProvider } from './pages/home/contexts/home-context'
import { themeColors } from './theme/default'
import { useApiConfig } from './misc/contexts/ApiConfigContext'
import useCurrentOrganization from './state/react-query/query/getCurrentOrganization'

const App = () => {
  const { apiConfig } = useApiConfig()
  const currentOrganization = useCurrentOrganization(apiConfig, !!apiConfig?.accessToken?.())

  useEffect(() => {
    LogRocket.init('n2brf1/scriptix-old-ui')
  }, [])
    
  return (
    <ThemeProvider theme={ theme(
      currentOrganization?.data?.resellerBranding?.brandColor || themeColors.scriptixBaseBlue) }>
      <CssBaseline />
      <HomeProvider>
        <RootRoute />
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={ false } />}
      </HomeProvider>
    </ThemeProvider>
  )
}

export default withTranslation()(App)
