import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: {
      width: '100%',
      height: 'fit-content',
      padding: '1em',
      borderRadius: theme.shape.borderRadius,
      [ theme.breakpoints.down('sm') ]: {
        width: '300px',
        margin: 'auto',
        marginBottom: '1em'
      },
      '&:hover': {
        boxShadow: `
          0px 4px 4px -72px rgb(0 0 0 / 10%), 
          0px 4px 4px 4px rgb(0 0 0 / 10%), 
          0px 4px 4px 4px rgb(0 0 0 / 10%)`
      }
    },
    formUpload: {
      display: 'flex',
      justifyContent: 'start'
    },
    dropzone: {
      textAlign: 'center',
      padding: '20px',
      border: '1px dashed',
      borderColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fafafa',
      color: theme.palette.primary.main
    },
    dropzoneContainer: {
      marginTop: '1%',
      cursor: 'pointer'
    },
    selectFieldstyle: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      border: '1px solid',
      '&. MuiMenuItem-root': {
        fontSize: '12px'
      },
      '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        display: 'none'
      },
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
        borderWidth: '0px',
        display: 'none'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none'
      }
    },
    formControl: {
      width: '100%',
      padding: '6% 0% 0% 0%'
    },
    switchStyle: {
      display: 'flex',
      margin: 'auto',
      padding: '6% 0% 0% 0%',
      width: '100%',
      textAlign: 'center',
      '& .MuiSwitch-track': {
        height: '20px',
        backgroundColor: 'gray'
      },
      '& .MuiSwitch-thumb': {
        height: '25px',
        width: '25px',
        color: theme.palette.primary.main
      }
    },
    textStyle: {
      marginTop: '4%',
      marginLeft: '1%',
      fontWeight: 800
    },
    checkBoxLabel: {
      margin: '0%',
      '& .MuiTypography-body1': {
        fontWeight: 800
      },
      '& .PrivateSwitchBase-root-26': {
        padding: '3%'
      }
    },
    submit: {
      margin: '5% 0% 0% 0%',
      width: '100%',
      textTransform: 'none',
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: '12px',
      '&:hover': {
        background: theme.palette.primary.dark
      }
    },
    menuItemStyle: {
      '&.MuiMenuItem-root': {
        fontSize: '14px',
        fontWeight: 600,
        '&:hover': {
          background: theme.palette.primary.main,
          color: 'white',
          borderRadius: '4px'
        }
      }
    },
    dropText: {
      fontSize: '16px',
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    uploadTopStatus: {
      display: 'flex',
      justifyContent: 'center',
      padding: '2%'
    },
    uploadingTypographyStyle: {
      marginRight: '5%',
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  })
)

export default useStyles
