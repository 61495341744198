import React from 'react'
import useNotificationStore from 'state/store/notifications'
import CustomSnackbar from '../Snackbar'

const NotificationSnackbar = () => {
  const { notifications, popNotification } = useNotificationStore()

  return notifications[ 0 ] ? <CustomSnackbar
    message={ notifications[ 0 ].message }
    severity={ notifications[ 0 ].severity }
    open={ true }
    handleClose={ () => popNotification(notifications[ 0 ]) }
  /> : <></>
}

export default NotificationSnackbar