import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import queryClient from './state/react-query'
import { ApiConfigProvider } from './misc/contexts/ApiConfigContext'
import { QueryClientProvider } from 'react-query'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a59676f51a8543dd88797486c3fae089@o191730.ingest.sentry.io/5872279',
    integrations: [ new Integrations.BrowserTracing() ],
    tracesSampleRate: 0.1,
  })
}

ReactDOM.render(
  <ApiConfigProvider>
    <QueryClientProvider client={ queryClient }>
      <App />
    </QueryClientProvider>
  </ApiConfigProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
